import PropTypes from "prop-types";
import React from "react";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-blue-600">
      <main className="flex-1 w-full">{children}</main>
      <footer className="bg-blue-600">
        <nav className="text-center p-4 mx-auto text-sm md:p-8">
          <p className="text-white">&copy; Ascend Global inc</p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
